import { makeStyles } from "@mui/styles";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useMetaMask } from "metamask-react";
import Box from "@mui/material/Box";
import Dummy from "../../../images/dummy.png";
import { Grid, IconButton, Typography, Button } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import axios, { AxiosRequestConfig } from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as NftsAction,
  selectors as NftsSelector,
} from "../../../store/NftStore/actions";
import NFTDisplay from "../../HomePage/NFTDisplay";

const useStyles = makeStyles(() => ({
  ImgBox: {
    width: "350px",
    height: "382px",
  },
  root: {
    backgroundColor: "rgba(255,255,255,0.1)",
    borderRadius: "10px",
    marginTop: "10px !important",
  },
  linkedAsset: {
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: "4px",
    padding: "5px",
    margin: "5px",
  },
  linkedObj: {
    color: "white",
  },
  linkedValue: {
    color: "#203C37",
    textAlign: "center",
    fontWeight: "bold",
  },
  proof: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  button: {
    marginTop: "15px !important",
    border: "1px solid #C5C142 !important",
    color: "#C5C142 !important",
  },
}));

function Portfolio() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { walletAddress } = useParams();
  const { account } = useMetaMask();
  let chainID = "";
  let moralisParams = "?chain=avalanche&format=decimal";

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      // @ts-ignore
      if (window?.ethereum) {
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window?.ethereum);
        const { chainId } = await provider.getNetwork();
        chainID = chainId.toString();
      } else {
        chainID = "43113";
      }
      getCollection();
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [walletAddress, account]);

  const getCollection = () => {
    const data = JSON.stringify({
      query: `query gallery($filter: AssetCollection_filter) {
 
        AssetCollection(filter: $filter) {
            contractAddress
        }
      }`,
      variables: {
        filter: {
          chain: {
            id: {
              _eq: chainID,
            },
          },
          status: {
            _eq: "published",
          },
        },
      },
    });

    const config: AxiosRequestConfig = {
      method: "POST",
      url: "https://farandole.directus.app/graphql",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const res = response.data?.data.AssetCollection;
        res.forEach((element: any, index: number) => {
          moralisParams =
            moralisParams + "&token_addresses=" + res[index]?.contractAddress;
        });
        if (walletAddress != "null" || account) {
          dispatch(NftsAction.getNfts(walletAddress, moralisParams));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const NftData = useSelector(NftsSelector.selectNftData);

  return (
    <Box sx={{ width: "100%" }}>
      {NftData && NftData?.length > 0 ? (
        <NFTDisplay NftData={NftData} />
      ) : (
        <>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            color={"white"}
            textAlign="center"
          >
            You don&apos;t have any Farandole NFT
          </Typography>

          <Grid container className={classes.root} spacing="10">
            <Grid item xs={3.5}>
              <img src={Dummy} className={classes.ImgBox} alt="Image" />
            </Grid>
            <Grid item xs={8.5}>
              <Typography
                variant="h3"
                color={"white"}
                textAlign="center"
                marginTop="30px"
              >
                “Bold & Dry”
              </Typography>
              <Typography variant="h5" color={"white"} marginTop="30px">
                Louis Jadot Chablis Cellier du Valvan
              </Typography>

              <div style={{ display: "flex" }}>
                <div className={classes.linkedAsset}>
                  <div className={classes.linkedObj}>Type Wine</div>
                  <div className={classes.linkedValue}>{"white"}</div>
                </div>

                <div className={classes.linkedAsset}>
                  <div className={classes.linkedObj}>Vintage</div>
                  <div className={classes.linkedValue}>{"2022"}</div>
                </div>

                <div className={classes.linkedAsset}>
                  <div className={classes.linkedObj}>Country</div>
                  <div className={classes.linkedValue}>{"France"}</div>
                </div>

                <div className={classes.linkedAsset}>
                  <div className={classes.linkedObj}>Grape variety 1</div>
                  <div className={classes.linkedValue}>{"Chardonnay"}</div>
                </div>
              </div>

              <Grid container marginTop="16px">
                <Grid item xs={2}>
                  <Typography color="white" fontWeight="300">
                    Sold by: Farandole
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="white" fontWeight="300">
                    Provenance Grade:{" "}
                    <IconButton
                      style={{ padding: "0px", color: "white" }}
                      //   onClick={() => setOpenGradeDetails(true)}
                    >
                      <HelpOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                    {"A+++"}
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="end">
                  <Typography
                    color="white"
                    fontWeight="300"
                    className={classes.proof}
                    // onClick={() => setOpenAuth(true)}
                  >
                    Proof of authenticity
                  </Typography>
                </Grid>
              </Grid>

              <Typography display="block" paddingTop="10px" color="white">
                Price: $150
              </Typography>

              <Button
                variant="outlined"
                className={classes.button}
                // onClick={() => setOpenDisclamer(true)}
              >
                Find NFT now
              </Button>

              <Typography variant="body2" color={"white"} paddingTop="10px">
                Pay in crypto or credit card
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default Portfolio;
