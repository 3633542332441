import { MORALIS_API_URL, MORALIS_API_KEY } from "../../constants";
import { GET_NFTS } from "./types";

const name = "nftsDataReducer";

export const actions = {
  getNfts: (wallet_address: string | undefined, data: string) => ({
    type: GET_NFTS,
    async: true,
    url: MORALIS_API_URL + wallet_address + "/nft" + data,
    method: "GET",
    header: {
      "X-API-Key": MORALIS_API_KEY,
      "content-type": "application/json",
    },
  }),
};

export const selectors = {
  selectNftData: (state: any) => state[name].data,
};
