import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ImageItem from "../../components/ImageItem";
import FilterNft from "./FilterNft";
import ReplayIcon from "@mui/icons-material/Replay";

import {
  actions as GalleryAction,
  selectors as GallerySelector,
} from "../../store/GalleryStore/actions";
import axios, { AxiosRequestConfig } from "axios";
import SliderComponent from "./Slider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '30px',
  },
  resetButton: {
    color: "#fff",
    background: "none",
    border: 0,
    textDecoration: "underline",
    cursor: "pointer",
  },
  reseticon: {
    color: "#fff",
    maxWidth: "16px",
  },
  galleryBox: {
    margin: "15px !important",
  },
  textData: {
    color: "#fff",
    margin: "20px !important",
    textAlign: "center",
  },
}));

export interface Ivintage {
  _lte?: any;
}

export interface IList {
  _in?: Array<string>;
}
export interface IColor {
  _in?: string;
}

export interface IAppelation {
  name?: IList;
}
export interface ISparkling {
  _eq?: boolean;
}
export interface IVariable {
  _and?: any;
}

export interface IFilterEq {
  vintage?: Ivintage;
  color?: IColor;
  appelation?: IAppelation;
  provider?: IAppelation;
  isSparkling?: ISparkling;
  category?: IColor;
  fromCollection?: any;
  _or?: any;
  varieties?: any;
  producer?: any;
  _and?: any;
}
export interface IFilterAnd {
  _and: IFilterEq[];
}

export interface IFilter {
  filter: IFilterAnd;
}


type filterVariables = IFilter;

let aData: any[] = [];
let pData: any[] = [];
let cData: string;
let sData: string;
let tData: string;
let fData: any;
let vData: any[] = [];
let pBData: any[] = [];
export default function GalleryPage() {
  const APP_STATE =
    window.location.href == "https://marketplace.farandole.io/"
      ? "prod"
      : "test";

  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValue: any[] | (() => any[]) = [];
  const [gallery, setGalleryData] = React.useState(initialValue);
  const today = new Date();
  const intialFilter = {
    filter: {
      _and: [
        {
          fromCollection: {
            chain: {
              envType: {
                _eq: APP_STATE
              }
            },
          },
        },
        {
          customReleaseDate: {
            ne: null,
          },
        },
        {
          customReleaseDate: {
            _lte: today.toISOString(),
          }
        }
      ]
    }
  };
  const [yearFilter, setYearFilter] = React.useState({
    vintageFrom: 0,
    vintageTo: 0,
  });

  const [stateData, setStateData] =
    React.useState<filterVariables>(intialFilter);
  const [filter, setFilter] = React.useState<IFilterEq>({});

  React.useEffect(() => {
    dispatch(GalleryAction.getGalleryData());
    dispatch(GalleryAction.getAppelationData());
    dispatch(GalleryAction.getBrandData());
    dispatch(GalleryAction.getProviderData());
    dispatch(GalleryAction.getCollectionData());
    dispatch(GalleryAction.getGrapeVarietyData());
    // declare the data fetching function
    const fetchData = async () => {
      // @ts-ignore
      const chainFilter = {
        fromCollection: {
          chain: {
            envType: {
              _eq: APP_STATE
            }
          }
        }
      };
      intialFilter.filter._and[1] = chainFilter;
      setStateData(intialFilter);
      getFilterData();
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [dispatch]);

  React.useEffect(() => {
    if (
      "appelation" in filter ||
      "provider" in filter ||
      "vintage" in filter ||
      "color" in filter ||
      "isSparkling" in filter ||
      "category" in filter ||
      "fromCollection" in filter ||
      "varieties" in filter ||
      "producer" in filter || 
      "_and" in filter
    ) {
      const stateData1 = stateData;
      if (aData.length === 0) {
        delete filter.appelation;
      }
      if (pData.length === 0) {
        delete filter.provider;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("provider")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (cData === "") {
        delete filter.color;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("color")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (!sData) {
        delete filter.isSparkling;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("isSparkling")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (tData === "") {
        delete filter.category;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("category")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (fData === "") {
        delete filter.fromCollection;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("fromCollection") && element.fromCollection.name) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (vData.length === 0) {
        delete filter.varieties;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("varieties")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (pBData.length === 0) {
        delete filter.producer;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("producer")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if(!yearFilter.vintageFrom || !yearFilter.vintageTo){
        delete filter._and;
        stateData1.filter._and.forEach((element, idx) => {
          if (element?.hasOwnProperty("_and")) {
            stateData1.filter._and.splice(idx, 1);
          }
        });
      }
      if (Object.getOwnPropertyNames(filter).length !== 0) {
        stateData1.filter._and.push(filter);
        setStateData(stateData1);
      }
    }
  }, [filter]);

  React.useEffect(() => {
    getFilterData();
  }, [filter]);

  const AppelationData = useSelector(GallerySelector.selectAppelationData);
  const BrandData = useSelector(GallerySelector.selectBrandData);
  const ProviderData = useSelector(GallerySelector.setProviderData);
  const CollectionData = useSelector(GallerySelector.setCollectionData);
  const GrapeVarietyData = useSelector(GallerySelector.setGrapeVarietyData);

  const getFilterData = () => {
    const data = JSON.stringify({
      query: `query gallery($filter: Asset_filter) {
      Asset(filter: $filter){
          description
          vintage
          nickname
          price
          avaxPrice
          grade
          category
          color
          isSparkling
          format
          tokenId
          id
          image {
              id
          }
          appelation {
              name
          }
          varieties {
              id
              Asset_Variety_id {
                  name
              }
          }
          provider {
              name
          }
          fromCollection {
            name
            contractAddress
          }
          producer {
            name
          }
  
      }
  
  }`,
      variables: stateData,
    });

    const config: AxiosRequestConfig = {
      method: "POST",
      url: "https://farandole.directus.app/graphql",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const GalleryData = response.data?.data?.Asset;
        setGalleryData(GalleryData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleResetFilter = () => {
    aData = [];
    pData = [];
    cData = "";
    sData = "";
    tData = "";
    fData = [];
    vData = [];
    pBData = [];

    setFilter({});
    setYearFilter({ vintageFrom: 0, vintageTo: 0 });

    const chainFilter = {
      fromCollection: {
        chain: {
          envType: {
            _eq: APP_STATE
          }
        }
      }
    };

    intialFilter.filter._and[1] = chainFilter;
    setStateData(intialFilter);
  };

  const handleFitlerChange = (event: any) => {
    setFilter({});
    if (event.target.name === "Appelation") {
      if (aData.indexOf(event.target.value) > -1) {
        const index = aData.findIndex(
          (item: any) => item === event.target.value
        );
        aData.splice(index, 1);
      } else {
        aData.push(event.target.value);
      }
      const appelation = {
        name: {
          _in: aData,
        },
      };
      setFilter((prevState) => ({
        ...prevState,
        appelation,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("appelation")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "Provider") {
      if (pData.indexOf(event.target.value) > -1) {
        const index = pData.findIndex(
          (item: any) => item === event.target.value
        );
        pData.splice(index, 1);
      } else {
        pData.push(event.target.value);
      }
      const provider = {
        name: {
          _in: pData,
        },
      };
      setFilter((prevState) => ({
        ...prevState,
        provider,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("provider")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }

    if (event.target.name === "Color") {
      if (cData === event.target.value) {
        cData = "";
      } else {
        cData = event.target.value;
      }
      const color = {
        _in: cData.toLowerCase(),
      };
      setFilter((prevState) => ({
        ...prevState,
        color,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("color")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "Sparkling") {
      let isSpark = false;
      if (event.target.value === sData) {
        isSpark = false;
        sData = "";
      } else {
        isSpark = event.target.value === "Yes" ? true : false;
        sData = event.target.value;
      }
      const isSparkling = {
        _eq: isSpark,
      };
      setFilter((prevState) => ({
        ...prevState,
        isSparkling,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("isSparkling")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "Type") {
      if (tData === event.target.value) {
        tData = "";
      } else {
        tData = event.target.value;
      }
      const category = {
        _in: tData.toLowerCase(),
      };
      setFilter((prevState) => ({
        ...prevState,
        category,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("category")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "Collection") {
      if (fData === event.target.value) {
        fData = "";
      } else {
        fData = event.target.value;
      }
      const fromCollection = {
        name: {
          _eq: fData,
        },
      };
      setFilter((prevState) => ({
        ...prevState,
        fromCollection,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("fromCollection") && element.fromCollection.name) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "GrapeVariety") {
      if (vData.indexOf(event.target.value) > -1) {
        const index = vData.findIndex(
          (item: any) => item === event.target.value
        );
        vData.splice(index, 1);
      } else {
        vData.push(event.target.value);
      }
      const varieties = {
        Asset_Variety_id: {
          name: {
            _in: vData,
          },
        },
      };
      setFilter((prevState) => ({
        ...prevState,
        varieties,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("varieties")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
    if (event.target.name === "Brand") {
      if (pBData.indexOf(event.target.value) > -1) {
        const index = pBData.findIndex(
          (item: any) => item === event.target.value
        );
        pBData.splice(index, 1);
      } else {
        pBData.push(event.target.value);
      }
      const producer = {
        name: {
          _in: pBData,
        },
      };
      setFilter((prevState) => ({
        ...prevState,
        producer,
      }));
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("producer")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
  };
  const handleYearFilter = (event: any) => {
    setFilter({});
    
    if (event.target.value) {
      if (event.target.value.replace(/[^0-9]/g, "")) {
        if (event.target.name === "vintageFrom") {
          yearFilter.vintageFrom = event.target.value;
          
        } else {
          yearFilter.vintageTo = event.target.value;
          
        }
        // setYearFilter({[event.target.name]: [event.target.value]});
    const year_filter = [];
        if(yearFilter.vintageFrom && yearFilter.vintageTo){
          const vintageFrom = {
            vintage:{
              "_gte": Math.floor(yearFilter.vintageFrom)
            }
          }
          const vintageTo = {
            vintage:{
              "_lte": Math.floor(yearFilter.vintageTo)
            }
          }
          year_filter.push(vintageFrom)
          year_filter.push(vintageTo);

          const _and = year_filter;
          setFilter((prevState) => ({
            ...prevState,
            _and,
          }));
          stateData.filter._and.forEach((element, idx) => {
            if (element?.hasOwnProperty("_and")) {
              stateData.filter._and.splice(idx, 1);
            }
          });
        }
        
        
      }
    } else {
      if (event.target.name === "vintageFrom") {
        yearFilter.vintageFrom = event.target.value;
        
      } else {
        yearFilter.vintageTo = event.target.value;
        
      }
      stateData.filter._and.forEach((element, idx) => {
        if (element?.hasOwnProperty("_and")) {
          stateData.filter._and.splice(idx, 1);
        }
      });
    }
  };
  return (
    <Grid container className={classes.root}>
      <Grid item md={2} xs={12} marginLeft="30px" marginRight="30px">
        <Grid  item style={{ display: "flex", cursor: "pointer"}}>
          <Typography style={{ paddingTop: "5px" }}>
            <ReplayIcon className={classes.reseticon} />
          </Typography>
          <button className={classes.resetButton} onClick={handleResetFilter}>
            Reset Filter
          </button>
        </Grid>
        <Grid  item style={{ display: "flex", cursor: "pointer" }}>
          <FilterNft
            handleFitlerChange={handleFitlerChange}
            handleReset={handleResetFilter}
            handleYearFilter={handleYearFilter}
            AppelationData={AppelationData}
            BrandData={BrandData}
            ProviderData={ProviderData}
            CollectionData={CollectionData}
            GrapeVarietyData={GrapeVarietyData}
            checkedBox={aData?.concat(
              pData,
              cData,
              sData,
              tData,
              fData,
              vData,
              pBData
            )}
            yearFilter={yearFilter}
          />
        </Grid>
      </Grid>

      <Grid item md={9} xs={12}>
        <Grid item xs={12}>
          <SliderComponent />
          <Grid container style={{display: 'flex', justifyContent: 'center'}}>
            {gallery && gallery.length > 0 ? (
              gallery?.map((details: any) => (
                <Grid item className={classes.galleryBox} key={details?.id}>
                  <ImageItem details={details} type="filter" />
                </Grid>
              ))
            ) : (
              <Typography className={classes.textData} variant="h4">
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
