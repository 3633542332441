import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  label: {
    color: "white",
    fontSize:'0.8rem !important'
  },
});

interface Options {
  id: number;
  name: string;
}
interface Props {
  options: Options[];
  title: string;
  handleFitlerChange: any;
  filterName: string;
  checkedBox: any
}
export default function RadioButton({
  options,
  title,
  handleFitlerChange,
  filterName,
  checkedBox
}: Props) {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
        {options?.map((item, index) => (
          <FormControlLabel
            value={item.name}
            name={filterName}
            key={index}
            classes={{ root: classes.label }}
            control={
              <Checkbox
                sx={{
                  color: "white",
                }}
                // @ts-ignore
                size="1rem"
                checked={checkedBox.indexOf(item.name) > -1 ? true : false}
                onChange={(event) => handleFitlerChange(event, title)}
              />
            }
            label={item.name}
          />
        ))}
    </FormControl>
  );
}
