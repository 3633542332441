import { makeStyles } from "@mui/styles";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MemberShipCard from "./MemberShipCard";
import Events from "./Events";
import Portfolio from "./Portfolio/Portfolio";

const useStyles = makeStyles(() => ({
  tabs: {
    justifyContent: "space-between !important",
  },
}));

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#C5C142",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

function ProfilePage() {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState("1");

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.name == "membership") {
      setValue("2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          style={{ width: "30%", margin: "auto" }}
          TabIndicatorProps={{ style: { background: "#C5C142" } }}
          classes={{ flexContainer: classes.tabs }}
        >
          <StyledTab label="Portfolio" value="1" />
          <StyledTab label="Membership cards" value="2" />
          <StyledTab label="Events tickets" value="3" />
        </Tabs>

        <TabPanel value={"1"}>
          <Portfolio />
        </TabPanel>
        <TabPanel value={"2"}>
          <MemberShipCard />
        </TabPanel>
        <TabPanel value={"3"}>
          <Events />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default ProfilePage;
