import { makeStyles } from "@mui/styles";
import { Button, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import { useState } from "react";
import DialogDetails from "./DialogDetails";

const useStyles = makeStyles({
  main: {
    maxWidth: "80%",
    margin: "auto",
  },
  main1: {
    maxWidth: "36%",
    margin: "auto",
  },
  container: {
    height: "468px",
    backgroundColor: "white",
    padding: "15px",
    paddingBottom: "0px !important",
  },
  img: {
    maxWidth: "300px",
    maxHeight: "250px",
    margin: "auto",
  },
  button: {
    fontSize: "0.675rem !important",
    backgroundColor: "#203C37 !important",
  },
  box: {
    border: "1px solid",
    borderColor: "#203C37 !important",
    borderRadius: "5px",
    backgroundColor: "rgba(32, 60, 55, 0.2)",
    padding: "5px",
  },
  button1: {
    backgroundColor: "#203C37 !important",
    maxHeight: "52px",
    maxWidth: "60px",
    // @ts-ignore
    textTransform: "none !important",
  },
});

const Metadata = ({ data }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const nftImg = data?.image ? 'https://gateway.pinata.cloud/ipfs/' + data?.image.substring(7) : '';

  return (
    <div className={classes.container}>
      <img src={nftImg} alt="nft" className={classes.img} />
      <Grid container justifyContent="space-between" marginTop="5px">
        <Button variant="contained" className={classes.button}>
          Proof of authenticity
        </Button>
        <Button variant="contained" className={classes.button}>
          About the wine
        </Button>
      </Grid>
      <Grid container spacing={2} marginTop="5px">
        {data?.attributes?.slice(0, 5)?.map((details: any, index: number) => (
          <Grid item key={index}>
            <Grid className={classes.box}>
              <Typography variant="body2" color="#203C37" textAlign="center">
                {details.trait_type}
              </Typography>
              <Typography variant="body2" color="#203C37" textAlign="center">
                {details.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Button
            variant="contained"
            className={classes.button1}
            onClick={() => setOpen(true)}
          >
            More here..
          </Button>
        </Grid>
      </Grid>
      {open && (
        <DialogDetails
          details={data?.attributes}
          handleDialogClose={() => setOpen(false)}
        />
      )}
    </div>
  );
};

function NFTDisplay({ NftData } : any) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: NftData?.length > 2 ? 3 : 1,
    slidesToScroll: 1,
  };
  return (
    <div className={NftData?.length > 2 ? classes.main : classes.main1}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        color="white"
        textAlign="center"
        marginTop="10px"
      >
        Wine NFT
      </Typography>
      <Slider {...settings}>
        {NftData?.length > 0 &&
          NftData?.map((item: any, index: number) => (
            <Metadata data={JSON.parse(item?.metadata)} key={index} />
          ))}
      </Slider>
    </div>
  );
}

export default NFTDisplay;
