import {
  GET_GALLERY_DATA,
  GET_APPELATION_DATA,
  GET_BRAND_DATA,
  GET_NFT_DETAILS,
  GET_FEATURE_DATA,
  GET_PROVIDER_DATA,
  GET_COLLECTION_DATA,
  GET_GRAPE_VARIETY_DATA,
} from "./types";

const initialGalleryState = {
  data: [],
  errorMessage: "",
  appelation: [],
  brand: [],
  provider: [],
  nftdetails: "",
  featureDate: [],
  collection: [],
  grapeVariety: [],
};

interface Props {
  data: any;
}

export function galleryDataReducer(state = initialGalleryState, action: any | Props) {
  switch (action.type) {
    case `${GET_GALLERY_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_GALLERY_DATA}_SUCCESS`:
      return Object.assign({}, state, { data: action.data.data });

    case `${GET_GALLERY_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_APPELATION_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_APPELATION_DATA}_SUCCESS`:
      return Object.assign({}, state, { appelation: action.data.data });

    case `${GET_APPELATION_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_PROVIDER_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_PROVIDER_DATA}_SUCCESS`:
      return Object.assign({}, state, { provider: action.data.data });

    case `${GET_PROVIDER_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_COLLECTION_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_COLLECTION_DATA}_SUCCESS`:
      return Object.assign({}, state, { collection: action.data.data });

    case `${GET_COLLECTION_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_GRAPE_VARIETY_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_GRAPE_VARIETY_DATA}_SUCCESS`:
      return Object.assign({}, state, { grapeVariety: action.data.data });

    case `${GET_GRAPE_VARIETY_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_NFT_DETAILS}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_NFT_DETAILS}_SUCCESS`:
      return Object.assign({}, state, { nftdetails: action.data.data.Asset[0] });

    case `${GET_NFT_DETAILS}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_BRAND_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_BRAND_DATA}_SUCCESS`:
      return Object.assign({}, state, { brand: action.data.data });

    case `${GET_BRAND_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    case `${GET_FEATURE_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });

    case `${GET_FEATURE_DATA}_SUCCESS`:
      return Object.assign({}, state, { featureDate: action.data.data.Feature });

    case `${GET_FEATURE_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    default:
      return state;
  }
}
