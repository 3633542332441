import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

interface Props {
  handleDialogClose: any;
  handleNext: any;
  title: string;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    color: "#203C37 !important",
    textAlign: "center",
  },
  des: {
    color: "#203C37 !important",
    maxHeight: "1200px",
  },
  action: {
    display: "block !important",
    textAlign: "center",
  },
  button: {
    width: "60%",
    backgroundColor: "#203C37 !important",
  },
});

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      <Typography
        variant="h5"
        className={classes.title}
        gutterBottom
        component="div"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#203C37",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogDisclaimer({
  handleDialogClose,
  title,
  handleNext,
}: Props) {
  const classes = useStyles();
  const listInnerRef = React.useRef<HTMLInputElement | null>(null);
  const [showButton, setShowButton] = React.useState(false);

  const onScroll = () => {
    setShowButton(true);
  };

  return (
    <div>
      <Dialog open={true} onClose={handleDialogClose} fullWidth maxWidth={"md"}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent onScroll={() => onScroll()} ref={listInnerRef}>
          <DialogContentText className={classes.des}>
            <Typography gutterBottom>
              The owner of each NFT has the ability to apply for an
              exchange/redemption of the digital to the physical. Each
              redemption will only be processed if the redeemer is in full
              compliance of their local jurisdiction drinking age. Each redeemer
              is also responsible for importation and customs laws. The
              redemption will only be satisfied if importation of liquor is
              allowed in that region. All redemptions are final and may not be
              reversed.
            </Typography>
            <Typography>
              Any use of this website is conditional on your acceptance of these
              terms and conditions of use and extends to our stated privacy
              policy.
            </Typography>
            <Typography>
              All Non-Fungible Tokens (NFTs) are created by the brands
              themselves or Farandole creates the NFTs on behalf of and
              alongside the brand. All NFTs are minted from each brand’s wallet.
            </Typography>
            <Typography>
              This Service also allows you to sell and purchase Crypto Assets.
              “Crypto Assets” refers to unique non-fungible tokens, implemented
              on the Ethereum blockchain (the “Ethereum Platform”) using smart
              contracts. ALL TRANSACTIONS INITIATED THROUGH OUR SERVICE ARE
              FACILITATED AND RUN BY THIRD-PARTY ELECTRONIC WALLET EXTENSIONS,
              AND BY USING OUR SERVICES YOU AGREE THAT YOU ARE GOVERNED BY THE
              TERMS OF SERVICE AND PRIVACY POLICY FOR THE APPLICABLE EXTENSIONS.
              FOR METAMASK, THOSE TERMS ARE AVAILABLE AT
              (https://metamask.io/terms.html) AND
              (https://metamask.io/privacy.html)
            </Typography>
            <Typography>
              Farandole IS A PLATFORM. WE ARE NOT A BROKER, FINANCIAL
              INSTITUTION, OR CREDITOR. THE SERVICES ARE AN ADMINISTRATIVE
              PLATFORM ONLY. FARANDOLE FACILITATES TRANSACTIONS BETWEEN THE
              BUYER AND SELLER IN THE AUCTION/SALE BUT IS NOT A PARTY TO ANY
              AGREEMENT BETWEEN THE BUYER AND SELLER OF CRYPTO ASSETS OR BETWEEN
              ANY USERS.
            </Typography>
            <Typography>
              THESE TERMS OF USE ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS, SO
              PLEASE READ THEM CAREFULLY. WE WANT TO LET YOU KNOW THAT THE TERMS
              INCLUDE AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED
              EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING
              AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
              AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND
              SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF
              OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
              PROCEEDING; AND (2) YOU ARE AGREEING TO MANDATORY INDIVIDUAL
              ARBITRATION FOR THE RESOLUTION OF DISPUTES AND WAIVING YOUR RIGHT
              TO A JURY TRIAL ON YOUR CLAIMS
            </Typography>
            <Typography>
              Unless otherwise indicated in writing by us, the Service and all
              content and other materials contained therein, including, without
              limitation, the Farandole logo and all designs, text, graphics,
              pictures, information, data, software, sound files, other files
              and the selection and arrangement thereof (collectively,
              “Content”) are the proprietary property of Farandole or our
              affiliates, licensors or users, as applicable.
            </Typography>
            <Typography>
              Notwithstanding anything to the contrary in these Terms, the
              Service and Content may include software components provided by
              Farandole or its affiliates or a third party that are subject to
              separate license terms, in which case those license terms will
              govern such software components.
            </Typography>
            <Typography>REDEMPTION</Typography>
            <Typography>
              Each NFT does not represent ownership in the actual physical asset
              it corresponds to. Each NFT is an independent work of art
              purchased directly from the brand. The owner of each NFT has the
              ability to apply for an exchange/redemption of the digital to the
              physical. Each redemption will only be processed if the redeemer
              is in full compliance of their local jurisdiction drinking age.
              Each redeemer is also responsible for importation and customs
              laws. The redemption will only be satisfied if importation of
              liquor is allowed in that region. All redemptions are final and
              may not be reversed.
            </Typography>
            <Typography>
              If the product cannot be redeemed, the NFT will remain in the
              custody of the attempted redeemer. If there is a mandatory
              redemption date set and the redeemer does not respond to
              Farandole, Farandole reserves the right to burn the NFT and
              reissue the token via an auction. Before doing so, Farandole will
              reach out via email and post on social channels. Farandole may
              only take these actions after a minimum of 6 months with no
              response from the NFT owner.
            </Typography>
            <Typography>TAKING BACK THE NFT</Typography>
            <Typography>
              In addition, Farandole retains the ability to take back the NFT.
              This is merely a security measure and can only be done in 2
              scenarios. 1) The NFT owner violates Farandole’s terms and
              conditions. 2) As a security measure for a consumer who loses
              access to their digital wallet.
            </Typography>
            <Typography>
              YOU MUST BE OF LEGAL DRINKING AGE IN ORDER TO REDEEM THE PHYSICAL
              ITEM FROM FARANDOLE. WE TAKE BEVERAGE ALCOHOL LAWS SERIOUSLY, AND
              IF YOU ATTEMPT TO PURCHASE OR RECEIVE BEVERAGE ALCOHOL BY PLACING
              AN ORDER ON FARANDOLE.COM, WE WILL ASSIST LAW ENFORCEMENT IN
              PROSECUTING YOU TO THE FULLEST EXTENT OF THE LAW.
            </Typography>
            <Typography>THE SALE OF ALCOHOLIC BEVERAGES</Typography>
            <Typography>
              Farandole does not sell alcohol to persons under the legal
              drinking age. By applying for redemption, you swear and affirm and
              represent to us that you are over the legal age required to buy
              alcoholic beverages and products and YOU ARE PURCHASING SOLELY FOR
              SELF CONSUMPTION YOU ALSO AFFIRM THAT THE STATE/COUNTRY IN WHICH
              YOU ARE ORDERING AND THE STATE/COUNTRY TO WHICH YOU ARE ASKING US
              TO DELIVER PERMIT THE SALE AND DELIVERY.
            </Typography>
            <Typography>
              We will rely upon the foregoing representations, and if we are
              held liable in the event that your representations are not true,
              and in such case, you hereby agree to indemnify us and/or
              reimburse us and the retailer(s) that sold the alcohol products
              and be responsible for all costs, expenses (including legal fees)
              and damages we and the retailer(s) suffer or incur.
            </Typography>
            <Typography>
              No contractual or other obligation to sell and ship the asset(s)
              redeemed via the Farandole.com platform attaches or is final or
              binding on us unless and until a compliance is ensured and ships
              the order to you (or the intended recipient, even if your credit
              or debit card is authorized or actually charged at the time of
              order placement or thereafter; in the event of that situation, the
              customer’s credit or debit card shall be credited back in full.
              Similarly, in that situation, you will not be responsible for the
              payment, nor will Farandole be responsible for providing any
              further compensation to you or any other party (other than the
              credit for the amount charged to your customer’s card).
            </Typography>
            <Typography>
              By using this site you are acknowledging that the person receiving
              a shipment of alcoholic beverages is of legal drinking age. You
              also agree that you are not permitted to resell alcohol purchased.
              If you cannot make these affirmations and representations or you
              do not agree with all of these conditions of use, you are not to
              use this site.
            </Typography>
            <Typography>
              By using the site, YOU AGREE TO BE BOUND BY THESE TERMS OF
              SERVICE, and all the terms, conditions, disclaimers and
              limitations that appear or are made available to you on our site
              (for example, in connection with special offers or promotions), as
              they may be amended from time to time, we may refer to herein
              collectively as your “Agreement” with us.
            </Typography>
            <Typography>
              If you have questions or concerns about our specific terms, please
              send an e-mail to contact@Farandole.com.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Link
            href="#"
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#203C37",
            }}
          >
            TERMS AND CONDITIONS
          </Link>
          <Button
            onClick={handleNext}
            variant="contained"
            className={classes.button}
            disabled={!showButton}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
