import * as React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip, Typography } from "@mui/material";

const useStyles = makeStyles({
  main: {
    // @ts-ignore
    position: "absolute !important",
  },

  iconShare: {
    // @ts-ignore
    position: "absolute !important",
    top: 0,
    right: 0,
  },

  border: {
    position: "absolute",
    marginTop: "-5px",
    zIndex: "100",
  },

  button: {
    cursor: "pointer",
    width: "250px",
    borderRadius: "5px",
    transition: "all 200ms",
    "&:hover": {
      transform: "scale(1.05)",
    },
    backgroundColor: "white",
  },

  contain: {
    backgroundColor: "white",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    border: "1px solid #C5C142 !important",
  },

  pictureContainer: {
    position: "relative",
    overflow: "hidden",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    border: "1px solid #C5C142 !important",
  },

  picture: {
    display: "block",
    height: "270px",
    maxWidth: "100%",
    margin: "auto",
  }
});

interface Props {
  details: any;
  type: string;
}

export default function ImageItem({ details, type }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid
      item
      className={classes.button}
      onClick={() =>
        navigate(
          "/assets/" +
            details?.fromCollection?.contractAddress +
            "/" +
            details?.id
        )
      }
    >
      <div className={classes.pictureContainer}>
        <img
          src={
            "https://farandole.directus.app/assets/" +
            (type === "data" ? details?.image : details?.image?.id)
          }
          alt="NFT"
          className={classes.picture}
        />
      </div>
      <Grid className={classes.contain}>
        <Tooltip title={details?.nickname} placement="top">
          <Typography
            padding="8px"
            paddingBottom={"0px"}
            fontWeight="800"
            noWrap
          >
            {details?.nickname.length > 25
              ? details?.nickname.slice(0, 24) + "..."
              : details?.nickname}
          </Typography>
        </Tooltip>

        <Typography paddingLeft="8px" fontWeight="400" fontSize={"0.8rem"}>
          {details?.vintage}
        </Typography>

        <div
          style={{
            display: "flex",
            padding: "0px 8px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            paddingTop="8px"
            fontSize="14px"
            lineHeight="13px"
            fontWeight="500"
          >
            {details?.provider?.name}
          </Typography>
          <Typography
            paddingTop="8px"
            fontSize="14px"
            lineHeight="13px"
            fontWeight="500"
          >
            {" "}
            Price
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            padding: "8px",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight="500" fontSize="14px">
            {details?.fromCollection?.name}
          </Typography>
          <Typography fontWeight="500" fontSize="14px">
            {details?.avaxPrice}
            {" "}AVAX
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
