import Box from "@mui/material/Box";
import MemberShip from "./membership";
import { Typography } from "@mui/material";

function MemberShipCard() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        color={"white"}
        textAlign="center"
      >
        You don&apos;t have any Farandole Membership card
      </Typography>
      <MemberShip />
    </Box>
  );
}

export default MemberShipCard;
