import * as React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import DetailsComponent from "./DetailsComponent";
import { useDispatch, useSelector } from "react-redux";
import AttributesPage from "./Attributes";
import {
  actions as NFTAction,
  selectors as NFTSelector,
} from "../../store/GalleryStore/actions";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  main: {
    paddingTop: "70px",
    paddingBottom: "30px",
    paddingRight: "70px",
    paddingLeft: "70px",
  },
  img: {
    maxWidth: "462px",
    maxHeight: "505px",
    borderRadius: "25px",
  },
  imgSmall: {
    maxWidth: "250px",
    borderRadius: "25px",
  },
  imageBox: {
    borderRadius: "25px",
    margin: "5px",
  },
});

interface Props {
  openDialog: any;
  setOpenDialog: any;
  status: any;
  torusConnected: any;
  getData: any;
}

export default function NftDetailsPage({openDialog, setOpenDialog, status, torusConnected, getData}: Props) {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const qdata = JSON.stringify({
    query: `query gallery($filter: Asset_filter) {
    Asset(filter: $filter){
        about
        vintage
        nickname
        price
        avaxPrice
        grade
        category
        color
        isSparkling
        format
        tokenId
        id
        image {
            id
        }
        appelation {
            name
            region {
              name
              country
          }
        }
        varieties {
            id
            Asset_Variety_id {
                name
            }
        }
        provider {
            name
        }
        producer {
          name
        }
        metadataAddress
        fromCollection {
            name
            contractAddress
            chain {
                id
                name
                explorerUrl  
            }
        }
        listing_id
    }

}`,
    variables: {
      filter: {
        id: {
          _eq: Number(id),
        },
      },
    },
  });

  React.useEffect(() => {
    dispatch(NFTAction.getNFTDetails(qdata));
  }, []);

  const data = useSelector(NFTSelector.selectNFTDetails);
  const isMobile = useMediaQuery({ maxWidth: 899 });
  return (
    <Grid container classes={{ root: classes.main }}>
      <Grid
        item
        md={6}
        xs={12}
        style={{ textAlign: "center", alignSelf: "center" }}
        className={classes.imageBox}
      >
        <img
          src={"https://farandole.directus.app/assets/" + data?.image?.id}
          alt="NFT"
          className={isMobile ? classes.imgSmall : classes.img}
        />
      </Grid>
      <Grid item md={6}>
        <DetailsComponent
          nickname={data?.nickname}
          provider={data?.provider?.name}
          collectionInfo={data?.fromCollection}
          metadataAddress={data?.metadataAddress}
          about={data?.about}
          price={data?.price}
          grade={data?.grade}
          tokenId={data?.tokenId}
          listingId={data?.listing_id}
          avaxPrice={data?.avaxPrice}
          image={data?.image?.id}
          openDialog={openDialog} setOpenDialog={setOpenDialog}
          status={status}
          torusConnected={torusConnected}
          getData={getData}
        />
      </Grid>
      <AttributesPage data={data} />
    </Grid>
  );
}
