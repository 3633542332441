import * as React from "react";
import { Divider, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMetaMask } from "metamask-react";
import { makeStyles } from "@mui/styles";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twiiter.png";
import instagram from "../../images/instagram.png";
import discord from "../../images/discord.png";
import linkedin from "../../images/linkedin.png";
import medium from "../../images/medium.png";
import avalanche from "../../images/avalanche.png";
import Logo from "../../images/logo.png";
import footer from "../../images/footer.svg";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles({
  main: {
    backgroundImage: `url(${footer})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#082D25",
    textAlign: "center",
    minHeight: 188,
    alignItems: "center",
  },
  contain: {
    // @ts-ignore
    textAlign: "initial !important",
  },
  containMobile: {
    textAlign: 'center',
  },
  divider2: {
    backgroundColor: "#C5C142 !important",
    marginLeft: "40% !important",
    marginRight: "40% !important",
    marginBlock: "0.35em !important",
  },
  link: {
    color: "#000",
    fontSize: "12px",
    textDecoration: "none",
  },
  textbutton: {
    cursor: "pointer",
    padding: "2px",
  },
});

export default function Footer() {
  const classes = useStyles();
  const { account } = useMetaMask();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 899 })

  return (
    <Grid
      container
      spacing={2}
      className={classes.main}
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        md={2}
        onClick={() => window.open("https://www.farandole.io/", "_blank")}
        marginTop="10px"
      >
        <img
          src={Logo}
          alt="Logo"
          width="94px"
          height="101px"
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />

      <Grid item xs={12} md={2} className={isMobile ? classes.containMobile : classes.contain}>
        <Typography
          variant="body1"
          display="block"
          gutterBottom
          color="#203C37"
          onClick={() =>
            window.open(
              "https://farandole.notion.site/farandole/Resources-5afd4526db8c40b0af12ce25c0ad4833"
            )
          }
          className={classes.textbutton}
        >
          HELP
        </Typography>
        <Typography
          variant="body1"
          display="block"
          gutterBottom
          color="#203C37"
          onClick={() =>
            window.open(
              "https://6u3lhpt6w65.typeform.com/to/nc46uabW?typeform-source=www.farandole.io"
            )
          }
          className={classes.textbutton}
        >
          CONTACT US
        </Typography>
        <Typography
          variant="body1"
          display="block"
          gutterBottom
          color="#203C37"
          onClick={() =>
            window.open("https://www.farandole.io/toques-clochers")
          }
          className={classes.textbutton}
        >
          MORE ABOUT BRANDS
        </Typography>
      </Grid>

      <Divider orientation="vertical" variant="middle" flexItem />

      <Grid item xs={12} md={2}>
        <Typography
          variant="body1"
          display="block"
          gutterBottom
          color="#203C37"
        >
          FARANDOLE CLUB
        </Typography>
        <Divider variant="middle" flexItem className={classes.divider2} />
        <Button
          variant="text"
          onClick={() =>
            navigate("/profile/" + account, { state: { name: "membership" } })
          }
          // @ts-ignore
          style={{ color: "#203C37", textTransform: "none" }}
        >
          NFT Membership Cards
        </Button>
      </Grid>

      <Divider orientation="vertical" variant="middle" flexItem />

      <Grid item xs={12} md={2} container justifyContent="space-around" paddingBottom='20px'>
        <Grid
          item
          onClick={() => window.open("https://twitter.com/FarandoleWine")}
          className={classes.textbutton}
        >
          <img src={twitter} alt="twitter" width="28px" height="23px" />
        </Grid>
        <Grid
          item
          onClick={() => window.open("https://discord.com/invite/zEu9mgVWTP")}
          className={classes.textbutton}
        >
          <img src={discord} alt="discord" width="29px" height="20px" />
        </Grid>
        <Grid
          item
          onClick={() =>
            window.open("https://www.instagram.com/farandole.official/")
          }
          className={classes.textbutton}
        >
          <img src={instagram} alt="instagram" width="23px" height="23px" />
        </Grid>
        <Grid
          item
          onClick={() =>
            window.open("https://www.facebook.com/farandole.official/")
          }
          className={classes.textbutton}
        >
          <img src={facebook} alt="facebook" width="12px" height="23px" />
        </Grid>
        <Grid
          item
          onClick={() =>
            window.open("https://www.linkedin.com/company/farandoleltd")
          }
          className={classes.textbutton}
        >
          <img src={linkedin} alt="linkedin" width="23px" height="23px" />
        </Grid>
        <Grid
          item
          onClick={() => window.open("https://medium.com/@Farandole")}
          className={classes.textbutton}
        >
          <img src={medium} alt="medium" width="38px" height="22px" />
        </Grid>
        <Grid
          item
          style={isMobile ? {} : { marginTop: "20px" }}
          onClick={() => window.open("https://www.avax.network/")}
          className={classes.textbutton}
        >
          <img src={avalanche} alt="avalanche" width={isMobile ? "70px" : "180px"} />
        </Grid>
      </Grid>
    </Grid>
  );
}
