import { history } from "../history";

export const ROUTING = "ROUTING";

export const routingMiddleware = () => (next: any) => (action: any) => {
  if (action.type === ROUTING) {
    history.push(action.to);
  }
  return next(action);
};
