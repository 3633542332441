import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  headingColor: {
    color: "#fff",
    paddingLeft: 10,
  },
  rootTable: {
    margin: "10px 0px",
  },
  table: {
    margin: 10,
  },
  attributeBox: {
    padding: 10,
    background: "rgba(255, 255, 255, 0.04)",
  },
  attributeSecondBox: {
    padding: 10,
  },
  attributeText: {
    textTransform: "capitalize",
    color: "rgba(255, 255, 255, 0.7)",
  },
  attributeTextValue: {
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  padding: {
    padding: 10,
  },
});

interface ArributeProps {
  data: any;
}
export default function AttributesPage({ data }: ArributeProps) {
  const classes = useStyles();
  const TableRowData = (props: any) => {
    if (props.key1 === "appelation") {
      return (
        <>
          {props?.item?.appelation?.region ? (
            <Grid container className={classes.attributeBox}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" className={classes.attributeText}>
                  Country
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  variant="body1"
                  className={classes.attributeTextValue}
                >
                  {props?.item?.appelation?.region.country}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {props?.item?.appelation?.region ? (
            <Grid container className={classes.attributeSecondBox}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" className={classes.attributeText}>
                  Region
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  variant="body1"
                  className={classes.attributeTextValue}
                >
                  {props?.item?.appelation?.region.name}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container className={classes.attributeBox}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" className={classes.attributeText}>
                Appellation
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="body1"
                className={classes.attributeTextValue}
              >
                {props?.item?.appelation?.name}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    } else if (props.key1 === "provider") {
      return (
        <Grid container className={classes.attributeSecondBox}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeText}>
              Provenance
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeTextValue}>
              {props?.item?.provider?.name}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (props.key1 === "producer") {
      return (
        <Grid container className={classes.attributeBox}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeText}>
              Producer
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeTextValue}>
              {props?.item?.producer?.name}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return <></>;
    }
  };
  const TableRowDataSecond = (props: any) => {
    if (props.key1 === "vintage") {
      return (
        <Grid container className={classes.attributeBox}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeText}>
              Vintage
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeTextValue}>
              {props?.item?.vintage}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (props.key1 === "color") {
      return (
        <Grid container className={classes.attributeSecondBox}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeText}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1" className={classes.attributeTextValue}>
              {props?.item?.color}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (props.key1 === "format") {
      const word = props?.item?.format?.split(/(\d+)/);
      return (
        <>
          <Grid container className={classes.attributeBox}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" className={classes.attributeText}>
                Container
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="body1"
                className={classes.attributeTextValue}
              >
                {word && word[0]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.attributeSecondBox}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" className={classes.attributeText}>
                Capacity
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="body1"
                className={classes.attributeTextValue}
              >
                {word && word[1]}
                {word && word[2]}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    } else if (props.key1 === "varieties") {
      return (
        <>
          <Grid container className={classes.attributeBox}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" className={classes.attributeText}>
                Grape Variety 1
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                variant="body1"
                className={classes.attributeTextValue}
              >
                {props?.item?.varieties[0]?.Asset_Variety_id?.name}
              </Typography>
            </Grid>
          </Grid>
          {props?.item?.varieties?.length >= 2 ? (
            <Grid container className={classes.attributeSecondBox}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" className={classes.attributeText}>
                  Grape Variety 2
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  variant="body1"
                  className={classes.attributeTextValue}
                >
                  {props?.item?.varieties[1]?.Asset_Variety_id?.name}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {props?.item?.varieties?.length >= 3 ? (
            <Grid container className={classes.attributeBox}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" className={classes.attributeText}>
                  Grape Variety 3
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  variant="body1"
                  className={classes.attributeTextValue}
                >
                  {props?.item?.varieties[2]?.Asset_Variety_id?.name}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {props?.item?.varieties?.length === 4 ? (
            <Grid container className={classes.attributeSecondBox}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" className={classes.attributeText}>
                  Grape Variety 4
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  variant="body1"
                  className={classes.attributeTextValue}
                >
                  {props?.item?.varieties[3]?.Asset_Variety_id?.name}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <Grid container className={classes.root}>
        <Typography variant="h4" className={classes.headingColor}>
          Attributes
        </Typography>
        <Grid container className={classes.rootTable}>
          <Grid item xs={12} md={6} lg={6} className={classes.padding}>
            {Object.keys(data).map((key, idx) => {
              return (
                <TableRowData key1={key} index={idx} key={idx} item={data} />
              );
            })}
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.padding}>
            {Object.keys(data).map((key, idx) => {
              return (
                <TableRowDataSecond
                  key1={key}
                  index={idx}
                  key={idx}
                  item={data}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
