import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Logo from "../../images/logo.png";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogBox from "../DialogBox";
import profileIcon from "../../images/profile.png";
import profileClickIcon from "../../images/profileClick.png";
import { useMediaQuery } from "react-responsive";


const useStyles = makeStyles({
  main: {
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
  },
  button: {
    // @ts-ignore
    textTransform: "none !important",
    marginLeft: "10px !important",
    color: "white !important",
    height: "50%",
    borderColor: "white !important",
  },
  buttonCursor: {
    cursor: "pointer",
    marginTop: "3px",
  },
  buttonIcon: {
    height: "36px",
  },
  connection: {
    display: 'flex',
    alignItems: 'center'
  },
  connectButton: {
    // @ts-ignore
    textTransform: "none !important",
    marginLeft: "10px !important",
    color: "#C5C142 !important",
    borderColor: "#C5C142 !important",
  },
  connectedButton: {
    // @ts-ignore
    textTransform: "none !important",
    marginLeft: "10px !important",
    color: "white !important",
    borderColor: "#C5C142 !important",
    marginTop: "23px !important",
    backgroundColor: "#C5C142 !important",
  },
  logo: {
    paddingLeft: "10%",
  }
});


interface Props {
  openDialog: any;
  setOpenDialog: any;
  status: any;
  account: any;
  torusConnected: any;
  torusParams: any;
  getData: any;
}


export default function Header({openDialog, setOpenDialog, status , account, torusConnected, torusParams, getData}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 899 })

  const handleConnect = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Toolbar>
        <div className={classes.main}>
          <div>
            <Grid onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              <img src={Logo} alt="logo" width="55px" height="59px" />
            </Grid>
          </div>
          <div className={classes.connection}>
            <div
              className={classes.buttonCursor}
              onClick={() => navigate("/profile/" + account)}
            >
              {/* Careful Temporary fix  */}
              {!isMobile ? (
                <img
                  src={
                    window.location.pathname?.substring(0, 8) === "/profile"
                      ? profileClickIcon
                      : profileIcon
                  }
                  className={classes.buttonIcon}
                />
              ) : (
                <></>
              )}
            </div>
            {status == "notConnected" && !torusConnected && (
              <Button
                className={classes.connectButton}
                onClick={handleConnect}
                variant="outlined"
              >
                Connect Wallet
              </Button>
            )}
            {status == "connecting" && (
              <Button className={classes.connectButton} variant="outlined">
                Connecting...
              </Button>
            )}

            {status == "connected" && (
              <Grid item>
                <Grid>
                  <Button
                    className={classes.connectedButton}
                    variant="outlined"
                  >
                    Connected
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="#C5C142">
                    To:{account.slice(0, 7) + "..."}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {torusConnected && (
              <Grid item>
                <Grid>
                  <Button
                    className={classes.connectedButton}
                    variant="outlined"
                  >
                    Connected
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography color="#C5C142">
                    To:{torusParams?.address.slice(0, 7) + "..."}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </Toolbar>
      {openDialog && (
        <DialogBox handleDialogClose={handleDialogClose} getData={getData} />
      )}
    </Box>
  );
}
