import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import RadioButton from "../RadioButton";

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    maxWidth: "100%",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "220px",
  },
}));

const useStyles = makeStyles({
  head: {
    backgroundColor: "transparent !important",
    border: "1px solid #EFEFEF",
    borderRadius: "5px !important",
  },
});

interface Options {
  id: number;
  name: string;
}
interface Props {
  expanded: string | false;
  handleChange: any;
  title: string;
  options: Options[];
  handleFitlerChange: any;
  filterName: string;
  checkedBox: any
}

export default function Collapsible({
  expanded,
  handleChange,
  title,
  options,
  handleFitlerChange,
  filterName,
  checkedBox
}: Props) {
  const classes = useStyles();
  return (
    <div>
      <Root>
        <Accordion
          classes={{ root: classes.head }}
          expanded={expanded === title}
          onChange={handleChange(title)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon style={{ color: "white" }} />}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, color: "white" }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioButton
              options={options}
              title={title}
              handleFitlerChange={handleFitlerChange}
              filterName={filterName}
              checkedBox={checkedBox}
            />
          </AccordionDetails>
        </Accordion>
      </Root>
    </div>
  );
}
