import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import Collapsible from "../../components/Collapsible";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { useMediaQuery } from "react-responsive";


const useStyles = makeStyles({
  contain: {
    maxWidth: "1280px",
    margin: "auto !important",
  },

  BoxSmall: {
    flexGrow: 1,
  },

  Box: {

  },

  title: {
    color: "white",
    textAlign: "center",
  },

  collapse: {
    marginTop: "10px",
  },

  button: {
    textTransform: "none",
    backgroundColor: "#FF31C3 !important",
    color: "white !important",
  },

  yearContainer: {
    backgroundColor: "transparent !important",
    border: "1px solid #EFEFEF",
    borderRadius: "5px !important",
    padding: "10px",
    color: "#fff",
  },

  textField: {
    marginRight: "10px !important",
    borderColor: "#fff",
  },
});

interface Props {
  handleFitlerChange?: any;
  handleYearFilter?: any;
  handleReset: any;
  AppelationData: any;
  BrandData: any;
  ProviderData: any;
  GrapeVarietyData: any;
  CollectionData: any;
  checkedBox?: any;
  yearFilter?: any;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 12,
    width: "74px",
    padding: "10px 4px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function FilterNft({
  handleFitlerChange,
  handleYearFilter,
  handleReset,
  AppelationData,
  BrandData,
  ProviderData,
  GrapeVarietyData,
  CollectionData,
  checkedBox,
  yearFilter,
}: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const isMobile = useMediaQuery({ maxWidth: 899 })

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={isMobile ? classes.BoxSmall : classes.Box } >
      <Grid className={classes.yearContainer}>
        <Typography>Vintage</Typography>
        <Grid item  style={{ display: "flex", marginTop: "10px" }}>
          <Grid item className={classes.textField}>
            <FormControl variant="standard" onReset={handleReset}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#fff" }}
              >
                From
              </InputLabel>
              <BootstrapInput
                id="vintageFrom"
                placeholder="1903"
                type="number"
                name="vintageFrom"
                value={yearFilter.vintageFrom || ""}
                onChange={handleYearFilter}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="standard">
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: "#fff" }}
              >
                To
              </InputLabel>
              <BootstrapInput
                id="vintageTo"
                placeholder="2022"
                type="number"
                name="vintageTo"
                value={yearFilter.vintageTo || ""}
                onChange={handleYearFilter}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Appellation"
          handleChange={handleChange}
          filterName="Appelation"
          options={AppelationData}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Provider"
          handleChange={handleChange}
          filterName="Provider"
          options={ProviderData}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Collection"
          handleChange={handleChange}
          filterName="Collection"
          options={CollectionData}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Brand"
          handleChange={handleChange}
          filterName="Brand"
          options={BrandData}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Grape Variety"
          handleChange={handleChange}
          filterName="GrapeVariety"
          options={GrapeVarietyData}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
      <Grid className={classes.collapse}>
        <Collapsible
          expanded={expanded}
          title="Color"
          handleChange={handleChange}
          filterName="Color"
          options={[
            {
              id: 1,
              name: "Red",
            },
            {
              id: 2,
              name: "White",
            },
          ]}
          handleFitlerChange={handleFitlerChange}
          checkedBox={checkedBox}
        />
      </Grid>
    </Box>
  );
}
