import * as React from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {
  actions as FeatureAction,
  selectors as FeatureSelector,
} from "../../store/GalleryStore/actions";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme: any) => ({
  centerTextSmall: {
    display: "flex", 
    justifyContent: 'center',
    color: "#fff",
  },
  textData: {
    color: "#fff",
    margin: "20px !important",
  },
  textColor: {
    color: "#fff",
  },
  containerRoot: {
    display: "flex !important",
    background: "rgba(255, 255, 255, 0.1)",
    padding: 15,
    borderRadius: "10px",
  },
  linkedAsset: {
    background: "rgba(245, 245, 245, 0.5)",
    borderRadius: 4,
    margin: 5,
    padding: 5,
  },
  linkedObj: {
    color: "#fff",
    fontSize: 12,
    textAlign: "center",
    margin: 5,
  },
  linkedValue: {
    fontSize: 12,
    textAlign: "center",
    margin: 5,
  },
  howtobtn: {
    color: "#C5C142 !important",
    border: "1px solid #C5C142 !important",
    background: "#082D25 !important",
  },
  driverBox: {
    textAlign: "center",
  },
  driver: {
    width: 321,
    height: 1,
    background: "#FFFFFF",
    margin: "auto !important",
  },
  connectWalletBtn: {
    padding: "10px 20px !important",
    color: "#C5C142 !important",
    border: "1px solid #C5C142 !important",
    background: "#082D25 !important",
  },
  dots: {
    bottom: "-20px",
    "& li.slick-active button::before": {
      color: "#fff",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#fff",
        opacity: 0.5,
      },
    },
  },
  ImgBox: {
    maxWidth: 200,
    maxHeight: 150,
    margin: "auto",
  },
}));

function LinkBox(props: any) {
  const classes = useStyles();
  if (props.key1 === "color") {
    return (
      <div className={classes.linkedAsset} key={props.idx}>
        <div className={classes.linkedObj}>Type Wine</div>
        <div className={classes.linkedValue}>{props?.item?.color}</div>
      </div>
    );
  } else if (props.key1 === "vintage") {
    return (
      <div className={classes.linkedAsset} key={props.idx}>
        <div className={classes.linkedObj}>Vintage</div>
        <div className={classes.linkedValue}>{props?.item?.vintage}</div>
      </div>
    );
  } else if (props.key1 === "appelation") {
    return (
      <div className={classes.linkedAsset} key={props.idx}>
        <div className={classes.linkedObj}>Country</div>
        <div className={classes.linkedValue}>
          {props?.item?.appelation?.region.country}
        </div>
      </div>
    );
  } else if (props.key1 === "varieties") {
    return (
      <div className={classes.linkedAsset} key={props.idx}>
        <div className={classes.linkedObj}>Variety</div>
        <div className={classes.linkedValue}>
          {props?.item?.varieties[0].Asset_Variety_id.name}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default function SliderComponent() {
  const classes = useStyles();
  const today = new Date();
  const isDesktop = useMediaQuery({ minWidth: 1201 })
  const isMedium = useMediaQuery( { maxWidth: 1200 })
  const isMobile = useMediaQuery({ maxWidth: 899 })

  const [greater_eq, setGreater_eq] = React.useState<string>(today.toISOString());

  const dispatch = useDispatch();

  const intialFilter = {
    filter: {
      _and: [
        {
          status: {
            _in: ["published"],
          },
        },
        {
          _or: [
            {
              expirationDate: {
                _null: true,
              },
            },
            {
              expirationDate: {
                _gte: greater_eq,
              },
            },
          ],
        },
      ],
    },
  };

  const data = JSON.stringify({
    query: `query gallery($filter: Feature_filter) {
            Feature(filter: $filter, sort: [ "rank" ]){
            description
            title
            expirationDate
            rank
            customImage {
                id
            }
            linkedAsset {
                nickname
                vintage
                color
                appelation {
                    region {
                        country
                    }
                }
                varieties {
                    Asset_Variety_id {
                        name
                    }
                }
            }
        }
    }`,
    variables: intialFilter,
  });

  React.useEffect(() => {
    dispatch(FeatureAction.getFeatureData(data));
  }, [greater_eq]);

  const featureData = useSelector(FeatureSelector.setFeatureData);

  const settings = !isMedium ? {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
    arrows: true,
  } : {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
    arrows: false,
  };
  return (
    <div style={isMobile ? {  } : { marginBottom: 40 }}>
      <div style={isMobile ? { } : { margin: 20 }}>
        <Typography className={classes.textData} variant="h4">
          Featured NFT
        </Typography>
        <Slider {...settings}>
          {featureData?.map((item: any, idx: number) => {
            return (
              <Grid container className={classes.containerRoot} key={idx}>
                <Grid item container xs={12} md={12} lg={3}>
                  <img
                    src={
                      "https://farandole.directus.app/assets/" +
                      (item.type === "data"
                        ? item?.customImage
                        : item?.customImage?.id)
                    }
                    className={classes.ImgBox}
                    alt="Image"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={9}>
                  <Typography
                    variant="h4"
                    className={isMedium ? classes.centerTextSmall : classes.textColor}
                    textAlign={"center"}
                    marginBottom="30px"
                  >
                    {item.title}
                  </Typography>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography className={isMedium ? classes.centerTextSmall : classes.textColor}>
                      {item.linkedAsset.nickname}
                    </Typography>
                    <div style={isDesktop ? { display: "flex" } : { display: "flex", justifyContent: 'center' }}>
                      {Object.keys(item.linkedAsset).map((key, idx) => {
                        return (
                          <LinkBox
                            item={item.linkedAsset}
                            key1={key}
                            index={idx}
                            key={idx}
                          />
                        );
                      })}
                    </div>
                    <Typography
                      className={classes.textColor}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Slider>
      </div>
      <div style={{ textAlign: "center", margin: 30 }}>
        <Button
          className={classes.howtobtn}
          variant="outlined"
          onClick={() =>
            window.open("https://www.farandole.io/with-credit-card")
          }
        >
          How to buy
        </Button>
      </div>
      <div className={classes.driverBox}>
        <Divider light className={classes.driver} />
      </div>
    </div>
  );
}
