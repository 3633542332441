import { combineReducers } from "redux";
import { nftsDataReducer } from "./NftStore/reducers";
import { galleryDataReducer } from "./GalleryStore/reducers";
import { eventDataReducer } from "./EventStore/reducers";

export const rootReducer = combineReducers({
  nftsDataReducer: nftsDataReducer,
  galleryDataReducer: galleryDataReducer,
  eventDataReducer: eventDataReducer,
});
