import { useMediaQuery } from "react-responsive";
import Cross from "../../images/cross.svg";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { MARKETPLACE_CONTRACT_ADDRESS } from "../../constants";
import MarketPlaceAbi from "../../NFTContractAbi/marketPlaceAbi.json";
import { ethers } from "ethers";
import Torus from '@toruslabs/torus-embed';


const useStyles = makeStyles({
  button: {
    backgroundColor: "#C5C142 !important",
    height: 50,
  },

  overlay: {
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(19, 18, 18, 0.705)",
    justifyContent: "center",
  },
  modalPayment: {
    width: "50%",
    height: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "25px",
    background:
      "linear-gradient(135deg, #C5C142, #C5C142 20%, #203C37 20%, #203C37)",
  },
  modalPaymentSmall: {
    width: "90%",
    height: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "25px",
    background:
      "linear-gradient(45deg, #C5C142, #C5C142 20%, #203C37 20%, #203C37)",
  },
});

interface Props {
  img: any;
  nickname: any;
  avaxPrice: any;
  tokenId: number;
  status: any;
  torusConnected: any;
  closeModal: () => void;
}
export default function PurchaseBox({ img, nickname, avaxPrice, tokenId, status, torusConnected, closeModal }: Props) {
  const isMobile = useMediaQuery({ maxWidth: 899 });
  const classes = useStyles();

  const buyNFT = async () => {
    const amount = String(avaxPrice);

    let provider;

    try {
      if (status == 'connected') {
        provider = new ethers.providers.Web3Provider(window?.ethereum);
      } else if (torusConnected) {

        const torus = new Torus();
        await torus.init();

        provider = new ethers.providers.Web3Provider(torus.provider)
      }

      const contract = new ethers.Contract(
        MARKETPLACE_CONTRACT_ADDRESS,
        MarketPlaceAbi,
        provider?.getSigner()
      );

      const tx = await contract.purchase(tokenId, {
        value: ethers.utils.parseEther(amount),
        gasLimit: 10000
      });

      await tx.wait();
    } catch (error: any) {
      console.error(error);
    }
  };
  return (
    <div className={classes.overlay} onClick={closeModal}>
      <div
        className={isMobile ? classes.modalPaymentSmall : classes.modalPayment}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={Cross}
          width="30px"
          height="30px"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={closeModal}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 30,
          }}
        >
          <img
            src={"https://farandole.directus.app/assets/" + img}
            alt="NFT"
            width="40%"
            height="auto"
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Typography color="white" variant="h6">
              {nickname}
            </Typography>
            <Typography color="white" variant="h6">
              Price: {avaxPrice} AVAX
            </Typography>
            <Button
              variant="contained"
              className={classes.button}
              onClick={buyNFT}
            >
              Buy now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
