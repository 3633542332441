import * as React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import DialogDetails from "../HomePage/DialogDetails";
import PurchaseBox from "./PurchaseBox";
import DialogBoxDetails from "./DialogBoxDetails";
import { ethers } from "ethers";
import { FARANDOLE_WALLET } from "../../constants";
import FarCollection1Abi from "../../NFTContractAbi/FarCollection1.json";
import axios from "axios";
import DialogBoxAuth from "./DialogBoxAuth";
import DialogDisclaimer from "./DialogDisclaimer";
import { useMediaQuery } from "react-responsive";
import DialogBox from "../../components/DialogBox";

const useStyles = makeStyles({
  main: {
    maxWidth: "1280px",
    margin: "auto",
  },
  iconFav: {
    color: "white !important",
  },
  iconShare: {
    color: "#C5C142 !important",
  },
  divider: {
    backgroundColor: "#474747 !important",
    marginTop: "20px !important",
  },
  proof: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  box: {
    border: "1px solid",
    borderColor: "white !important",
    borderRadius: "5px",
    backgroundColor: "rgba(245, 245, 245, 0.5)",
    padding: "5px",
  },
  button1: {
    backgroundColor: "white !important",
    maxHeight: "52px",
    maxWidth: "60px",
    color: "black !important",
    // @ts-ignore
    textTransform: "none !important",
  },
  button: {
    backgroundColor: "#C5C142 !important",
    height: 50,
  },
  justify: {
    textAlign: "justify",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-out",
  },
  modalSmall: {
    width: "90%",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-out",
  },
});

interface Props {
  nickname: any;
  provider: string;
  about: string;
  price: any;
  grade: any;
  tokenId: number;
  metadataAddress: string;
  collectionInfo: any;
  listingId: string;
  avaxPrice: any;
  image: string;
  openDialog: any;
  setOpenDialog: any;
  status: any;
  torusConnected: any;
  getData: any;
}

export default function DetailsComponent({
  nickname,
  provider,
  about,
  grade,
  tokenId,
  metadataAddress,
  collectionInfo,
  avaxPrice,
  image,
  openDialog, setOpenDialog,
  status,
  torusConnected,
  getData
}: Props
) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openGradeDetails, setOpenGradeDetails] = React.useState(false);
  const [openAuth, setOpenAuth] = React.useState(false);
  const [openDisclamier, setOpenDisclamer] = React.useState(false);
  const [data, setData] = React.useState([]);
  const isMobile = useMediaQuery({ maxWidth: 899 });
  const listing = React.useRef<HTMLDivElement>(null);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [ownedByFarandole, setOwner] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const metadata = await axios.get(metadataAddress);
        setData(metadata?.data?.attributes);
      } catch(error) {}
    };
    fetchData();
  }, [tokenId, metadataAddress]);


  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleBuyNow = async () => {
    if (status == 'connected' || torusConnected) {
      setOpenDisclamer(true);
    } else {
      setOpenDialog(true);
    }
  };

  React.useEffect(() => {
    const getOwnerNFT = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window?.ethereum);
        const contract = new ethers.Contract(
          collectionInfo?.contractAddress,
          FarCollection1Abi,
          provider.getSigner()
        );
        const owner = await contract.ownerOf(tokenId);
        if (owner === FARANDOLE_WALLET) setOwner(true);
        else setOwner(false);
      } catch(error) {}
    };
    getOwnerNFT();
  }, [tokenId, metadataAddress, collectionInfo?.contractAddress]);

  const closeModal = () => {
    setOpenPurchase(false);
  };

  const purchaseProps = {
    img: image,
    nickname: nickname,
    avaxPrice: avaxPrice,
    tokenId: tokenId,
    status: status,
    torusConnected: torusConnected,
    closeModal: closeModal
  };

  return (
    <Grid container classes={{ root: classes.main }}>
      <Grid item md={12} minWidth="100%">
        <Typography color="white" variant="h6" component="div">
          {isMobile ? (
            <h3 style={{ textAlign: "center" }}>{nickname}</h3>
          ) : (
            <h1 style={{ textAlign: "center" }}>{nickname}</h1>
          )}
        </Typography>
      </Grid>
      <Grid minWidth="100%">
        <Typography
          color="white"
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          <h2 style={{ textAlign: "center" }}>About the wine</h2>
        </Typography>
      </Grid>

      <Typography
        color="white"
        variant="subtitle2"
        fontSize={"medium"}
        gutterBottom
        component="div"
        className={classes.justify}
      >
        {about ? about : "No description for this wine."}
      </Typography>
      <Grid container marginTop="16px">
        <Grid item xs={4}>
          <Typography color="white" fontWeight="300">
            Sold by: {provider}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="white" fontWeight="300">
            Provenance Grade:{" "}
            <IconButton
              style={{ padding: "0px", color: "white" }}
              onClick={() => setOpenGradeDetails(true)}
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </IconButton>
            {grade?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="end">
          <Typography
            color="white"
            fontWeight="300"
            className={classes.proof}
            onClick={() => setOpenAuth(true)}
          >
            Proof of authenticity
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "baseline", gap: 20 }}>
          <Typography color="white">Price:</Typography>
          <Typography color="white">{avaxPrice} AVAX</Typography>
        </div>
        <div
          style={isMobile ? { display: "flex", justifyContent: "center" } : {}}
        >
          {ownedByFarandole ? (
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleBuyNow}
            >
              Buy now
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              disabled={true}
            >
              Sold Out
            </Button>
          )}
        </div>
      </div>

      {open && (
        <DialogDetails
          details={data}
          handleDialogClose={() => setOpen(false)}
        />
      )}

      {openDetails && (
        <DialogBoxDetails
          title={"Buy your NFT in less than a minute"}
          des="Pay in cash with credit card with Stripe, the biggest payment processing platform Or connect your crypto wallet to pay with crypto AVAX"
          handleDialogClose={() => setOpenDetails(false)}
        />
      )}

      {openGradeDetails && (
        <DialogBoxDetails
          title={"Provenance grade"}
          des="Provenance grade is the level of information on authenticity and the level of care of how the wine have been stored. A+++ is the highest grade and A is the lowest."
          handleDialogClose={() => setOpenGradeDetails(false)}
        />
      )}

      {openDisclamier && (
        <DialogDisclaimer
          title={"Disclaimer"}
          handleDialogClose={() => setOpenDisclamer(false)}
          handleNext={() => {
            setOpenDisclamer(false);
            setOpenPurchase(true);
          }}
        />
      )}

      {openAuth && (
        <DialogBoxAuth
          handleDialogClose={() => setOpenAuth(false)}
          tokenId={tokenId}
          provider={provider}
          collectionInfo={collectionInfo}
          metadataAddress={metadataAddress}
        />
      )}

      {openDialog && (
        <DialogBox handleDialogClose={handleDialogClose} getData={getData}/>
      )}

      {openPurchase && <PurchaseBox {...purchaseProps} />}
      <div
        className={isMobile ? classes.modalSmall : classes.modal}
        id="listing"
        ref={listing}
      ></div>
    </Grid>
  );
}
