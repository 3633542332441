import { GET_EVENT_DATA } from "./types";

const initialGalleryState = {
  data: [],
  errorMessage: "",
};

interface Props {
  data: any;
}

export function eventDataReducer(
  state = initialGalleryState,
  action: any | Props
) {
  switch (action.type) {
    case `${GET_EVENT_DATA}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });
    case `${GET_EVENT_DATA}_SUCCESS`:
      return Object.assign({}, state, {
        data: action.data.data.Event,
      });
    case `${GET_EVENT_DATA}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    default:
      return state;
  }
}
