import { DIRECTUS_API_URL } from "../../constants";
import {
  GET_GALLERY_DATA,
  GET_APPELATION_DATA,
  GET_BRAND_DATA,
  GET_FILTER_DATA,
  GET_NFT_DETAILS,
  GET_FEATURE_DATA,
  GET_PROVIDER_DATA,
  GET_GRAPE_VARIETY_DATA,
  GET_COLLECTION_DATA,
} from "./types";

const name = "galleryDataReducer";

export const actions = {
  getGalleryData: () => ({
    type: GET_GALLERY_DATA,
    async: true,
    url: DIRECTUS_API_URL + "items/Asset",
    method: "GET",
  }),

  getNFTDetails: (data: any) => ({
    type: GET_NFT_DETAILS,
    async: true,
    url: "https://farandole.directus.app/graphql",
    payload: data,
    method: "POST",
    header: {
      "Content-Type": "application/json",
    },
  }),

  getAppelationData: () => ({
    url: DIRECTUS_API_URL + "items/Appelation?filter[status][_eq]=published",
    type: GET_APPELATION_DATA,
    async: true,
    method: "GET",
  }),

  getBrandData: () => ({
    type: GET_BRAND_DATA,
    async: true,
    url: DIRECTUS_API_URL + "items/Producer_Brand?filter[status][_eq]=published",
    method: "GET",
  }),

  getProviderData: () => ({
    type: GET_PROVIDER_DATA,
    async: true,
    url: DIRECTUS_API_URL + "items/Provider?filter[status][_eq]=published",
    method: "GET",
  }),

  getCollectionData: () => ({
    type: GET_COLLECTION_DATA,
    async: true,
    url: DIRECTUS_API_URL + "items/AssetCollection?filter[status][_eq]=published",
    method: "GET",
  }),

  getGrapeVarietyData: () => ({
    type: GET_GRAPE_VARIETY_DATA,
    async: true,
    url: DIRECTUS_API_URL + "items/Asset_Variety?filter[status][_eq]=published",
    method: "GET",
  }),

  getFilterData: () => ({
    type: GET_FILTER_DATA,
    async: true,
    url: DIRECTUS_API_URL + "graphql",
    method: "POST",
  }),

  getFeatureData: (data: any) => ({
    type: GET_FEATURE_DATA,
    async: true,
    url: "https://farandole.directus.app/graphql",
    method: "POST",
    payload: data,
    header: {
      "Content-Type": "application/json",
    },
  }),
};

export const selectors = {
  selectGalleryData: (state: any) => state[name].data,
  selectNFTDetails: (state: any) => state[name].nftdetails,
  selectAppelationData: (state: any) => state[name].appelation,
  selectBrandData: (state: any) => state[name].brand,
  setProviderData: (state: any) => state[name].provider,
  setCollectionData: (state: any) => state[name].collection,
  setGrapeVarietyData: (state: any) => state[name].grapeVariety,
  setFilterData: (state: any) => state[name],
  setFeatureData: (state: any) => state[name].featureDate,
};
