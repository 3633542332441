import { GET_NFTS } from "./types";

const initialNftsState = {
  data: [],
  errorMessage: "",
};

interface Props {
  data: any;
}

export function nftsDataReducer(state = initialNftsState, action: any | Props) {
  switch (action.type) {
    case `${GET_NFTS}_REQUEST`:
      return Object.assign({}, state, { errorMessage: "" });
    case `${GET_NFTS}_SUCCESS`:
      return Object.assign({}, state, {
        data: action.data.result,
      });
    case `${GET_NFTS}_FAILURE`:
      return Object.assign({}, state, { errorMessage: action.error });

    default:
      return state;
  }
}
