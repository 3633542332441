import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

interface Props {
  handleDialogClose: any;
  tokenId: number;
  provider: string;
  metadataAddress: string;
  collectionInfo: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    color: "#203C37 !important",
    textAlign: "center",
  },
});

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      <Typography
        variant="h5"
        className={classes.title}
        gutterBottom
        component="div"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#203C37",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogBoxAuth({
  handleDialogClose,
  tokenId,
  provider, metadataAddress,
    collectionInfo
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} onClose={handleDialogClose} fullWidth>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          {"Proof of authenticity"}
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText className={classes.title}>
            <Grid container justifyContent="space-between" marginTop="10px">
              <Grid>Chain</Grid>
              <Grid>{collectionInfo.chain.name}</Grid>
            </Grid>

            <Grid container justifyContent="space-between" marginTop="10px">
              <Grid>Collection Name</Grid>
              <Grid>{collectionInfo.name}</Grid>
            </Grid>

            <Grid container justifyContent="space-between" marginTop="10px">
              <Grid>Token ID</Grid>
              <Grid>{tokenId}</Grid>
            </Grid>

            <Divider style={{ marginTop: "10px" }} />

            <Grid container justifyContent="space-between" marginTop="10px">
              <Grid>NFT Type</Grid>
              <Grid>ERC-721</Grid>
            </Grid>

            <Divider style={{ marginTop: "10px" }} />

            <Grid container justifyContent="space-between" marginTop="10px">
              <Grid>Issuer</Grid>
              <Grid>{provider}</Grid>
            </Grid>

            <Divider style={{ marginTop: "10px" }} />

            <Grid
              container
              justifyContent="space-between"
              marginTop="10px"
              alignItems="center"
            >
              <Grid>Contract Address</Grid>
              <Grid>
                {collectionInfo?.contractAddress.substring(0, 20) + "...."}
                <IconButton
                  onClick={() =>
                    navigator.clipboard.writeText(collectionInfo?.contractAddress)
                  }
                >
                  <ContentCopyRoundedIcon />
                </IconButton>
              </Grid>

            </Grid>

            <Grid
              container
              justifyContent="space-between"
              marginTop="10px"
              fontWeight="600"
            >
              <Grid />
              <Grid>
                View full blockchain details
                <IconButton
                  onClick={() =>
                    window.open(
                      `${collectionInfo?.chain.explorerUrl}/address/${collectionInfo?.contractAddress}`
                    )
                  }
                >
                  <ArrowForwardOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                Metadata
                <IconButton
                    onClick={() =>
                        window.open(
                            `${metadataAddress}`
                        )
                    }
                >
                  <ArrowForwardOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
