import Axios from "axios";

export const asynchronousAPIMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const request = () => ({ type: `${action.type}_REQUEST` });
    const success = (data: any) => ({ type: `${action.type}_SUCCESS`, data });
    const failure = (error: any) => ({ type: `${action.type}_FAILURE`, error });

    if (action.hasOwnProperty("async") && action.async) {
      store.dispatch(request());

      Axios.request({
        method: action.method,
        baseURL: action.url,
        headers: action.header,
        data: action.payload,
      }).then(async (response) => {
          if (response.status === 200) {
            const data = await response.data;
            store.dispatch(success(data));
          } else {
            store.dispatch(failure(response));
          }
        }).catch((error) => console.log(error));
    }

    return next(action);
  };
