import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import MembershipImg from "../../../images/membership.svg";

const useStyles = makeStyles((theme: any) => ({
  main: {
    maxWidth: "1280px",
    marginTop: "28px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "0px",
    },
  },
  mainContainer: {
    marginTop: "50px",
  },
  headingLabel: {
    color: "#fff",
    flexGrow: 1,
    textAlign: "center",
  },
  container: {
    marginTop: "40px",
  },
  subHeading: {
    color: "#fff",
    marginBottom: "20px",
  },
  text: {
    color: "#fff",
    marginTop: "20px !important",
  },
  imgCenter: {
    textAlign: "center",
  },
  secondcontainer: {
    textAlign: "center",
    margin: "0 250px !important",
  },
}));

function MemberShip() {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.main}>
        <Grid container className={classes.mainContainer}>
          <Typography variant="h3" className={classes.headingLabel}>
            Farandole Club
          </Typography>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item sm={12} md={6} lg={6}>
            <Typography variant="h4" className={classes.subHeading}>
              NFT Membership card
            </Typography>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                Farandole is a diverse community of wine & spirits
                professionals, passionate collectors, artists and blockchain
                enthusiasts. Our name comes from a human-chain dance. We are a
                long chain of different personalities, connoisseurs, dedicated
                producers and brand owners, investors, NFT enthusiasts, artists,
                sommeliers, chefs and innovators. Farandole’s membership card
                allows community members to access exclusive events in both the
                metaverse and the real world. Along with the membership card
                users will receive tokens. Members will also receive access to
                attractive promotions and product releases on the Marketplace.
                In the Metaverse, members will be able to connect with one
                another and show off their collections. Farandole club enable
                the brand to connect with the members, to offer dedicated
                services through NFTs (exclusive dinner, visit of the vineyard,
                ...) as well as redeem their virtual bottles as physical bottles
                in the real world.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} lg={6} className={classes.imgCenter}>
            <video
              loop
              src="https://rcd-media.com/farandole/membershipcards/1.mp4"
              poster={MembershipImg}
              autoPlay
              height="80%"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MemberShip;
