import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../store/index";
import Header from "../components/Header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../components/Footer";
import { makeStyles } from "@mui/styles";
import BackgroundVisual from "../images/Background_visual.png";
import { Grid } from "@mui/material";
import GalleryPage from "../views/GalleryPage";
import NftDetailsPage from "../views/NftDetailsPage";
import ProfilePage from "../views/ProfilePage";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useState } from "react";
import { useMetaMask } from "metamask-react";

const useStyles = makeStyles(() => ({
  main: {
    backgroundImage: `url(${BackgroundVisual})`,
    backgroundPositionY: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: window.innerHeight - 172,
  },
  contain: {
    minHeight: window.innerHeight,
    minWidth: "100%",
  },
}));

function App() {
  const classes = useStyles();
  const theme = createTheme();

  const [openDialog, setOpenDialog] = useState(false);
  const [torusConnected, setTorusConnected] = useState<boolean>(false);
  const [torusParams, setTorusParams] = useState<any>();

  const { status, account } = useMetaMask();


  function getData(torus: any, params: any) {
    if (torus) {
      setTorusConnected(true);
      setTorusParams(params);
    }
  }

  return (
    <main className={classes.contain}>
      <TawkMessengerReact
        propertyId="629f79cfb0d10b6f3e7628b1"
        widgetId="1g4vf7lav"
      />
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Grid className={classes.main}>
              <Header openDialog={openDialog} setOpenDialog={setOpenDialog} status={status} account={account} torusConnected={torusConnected} torusParams={torusParams} getData={getData}/>
              <Routes>
                <Route path="/" element={<GalleryPage />} />
                <Route
                  path="/assets/:contractAddress/:id"
                  element={<NftDetailsPage openDialog={openDialog} setOpenDialog={setOpenDialog} status={status} torusConnected={torusConnected} getData={getData}/>}
                />
                <Route
                  path="/profile/:walletAddress"
                  element={<ProfilePage />}
                />
              </Routes>
            </Grid>
            <Footer />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </main>
  );
}

export default App;
