import { GET_EVENT_DATA } from "./types";

const name = "eventDataReducer";

export const actions = {
  getEventData: (data: any) => ({
    type: GET_EVENT_DATA,
    async: true,
    url: "https://farandole.directus.app/graphql",
    method: "POST",
    payload: data,
    header: {
      "Content-Type": "application/json",
    },
  }),
};

export const selectors = {
  selectEventData: (state: any) => state[name].data,
};
