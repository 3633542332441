import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

interface Props {
  handleDialogClose: any;
  title: string;
  des: string;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    color: "#203C37 !important",
    textAlign: "center",
  },
  image: {
    height: "84px !important",
    width: "84px !important",
    alignSelf: "center",
  },
  imagebutton: {
    height: "84px !important",
    width: "84px !important",
    alignSelf: "center",
    marginTop: "23px !important",
  },
});

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      <Typography
        variant="h5"
        className={classes.title}
        gutterBottom
        component="div"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#203C37",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogBoxDetails({
  handleDialogClose,
  title,
  des,
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} onClose={handleDialogClose} fullWidth>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText className={classes.title}>
            <Typography gutterBottom>{des}</Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
