import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

interface Props {
  handleDialogClose: any;
  details: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    color: "#203C37 !important",
    textAlign: "center",
  },
  box: {
    border: "1px solid",
    borderColor: "#203C37 !important",
    borderRadius: "5px",
    backgroundColor: "rgba(32, 60, 55, 0.2)",
    padding: "5px",
  },
});

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      <Typography
        variant="h5"
        className={classes.title}
        gutterBottom
        component="div"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#203C37",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogDetails({ handleDialogClose, details }: Props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} onClose={handleDialogClose} fullWidth>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          Specificities
        </BootstrapDialogTitle>
        <Grid
          container
          spacing={2}
          marginBottom="20px"
          paddingLeft="15px"
          paddingRight="1px"
        >
          {details?.map((details: any, index: number) => (
            <Grid item key={index}>
              <Grid className={classes.box}>
                <Typography variant="body2" color="#203C37" textAlign="center">
                  {details.trait_type}
                </Typography>
                <Typography variant="body2" color="#203C37" textAlign="center">
                  {details.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </div>
  );
}
