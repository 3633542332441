import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import {
  actions as EventAction,
  selectors as EventSelector,
} from "../../store/EventStore/actions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import locationIcon from "../../images/location.png";


const useStyles = makeStyles(() => ({
  tabs: {
    justifyContent: "space-around !important",
  },
  rootBox:{
    margin: "20px 100px"
  },
  root: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: 25,
    margin: 10,
    display: "flex !important",
    minHeight: 400
  },
  ImgBox: {
    maxWidth: 300,
    maxHeight: 150,
    margin: "auto",
    marginTop: "10px",
    borderRadius: "4px"
  },
  location:{
    marginRight: "5px"
  },
  ticketBtn: {
    marginTop: "10px !important",
    padding: "8px 16px !important",
    background: "#203C37 !important"
  }
}));

function Events() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const qdata = JSON.stringify({
    query: `query ($filter: Event_filter){
        Event(filter: $filter){
            name
            description
            date
            location
            link
            image {
                id
            }
        }
       }`,
    variables: {
      filter: {
        status: {
          _eq: "published",
        },
      },
    },
  });

  useEffect(() => {
    dispatch(EventAction.getEventData(qdata));
  }, []);

  const data = useSelector(EventSelector.selectEventData);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={classes.rootBox}>
      <Typography variant="h6" gutterBottom textAlign={"center"} color="white">
        You don&apos;t have any Farandole event tickets
      </Typography>

      <Slider {...settings}>
        {data.length > 0 &&
          data.map((item: any, index: number) => (
            <Grid container className={classes.root} key={index}>
              <Grid item xs={3} md={3} lg={3} style={{margin: "auto"}}>
                <img
                  src={
                    "https://farandole.directus.app/assets/" + item?.image?.id
                  }
                  className={classes.ImgBox}
                  alt="Image"
                />
              </Grid>
              <Grid item xs={9} md={9} lg={9}>
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  color={"#203C37"}
                  textAlign="center"
                  marginBottom={"30px"}
                >
                  Get your tickets now!
                </Typography>
                <Grid item xs={12} md={12} lg={12} style={{marginLeft: "10px"}}>   
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    color={"#203C37"}
                    fontWeight="600"
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    color={"#203C37"}
                    marginBottom="30px"
                  >
                    {item.date}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    color={"#203C37"}
                    display="flex"
                  >
                    <img src={locationIcon} className={classes.location}/>
                    {item.location}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    color={"#203C37"}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></Typography>
                    <Button
                      className={classes.ticketBtn}
                      variant="contained"
                      target="_blank"
                      href={item.link}
                    >Get tickets</Button>

                </Grid>
              </Grid>
            </Grid>
          ))}
      </Slider>
    </div>
  );
}

export default Events;
