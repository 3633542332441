import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import MetaMask from "../../images/MetaMask.png";
import TorusLogo from "../../images/torus-logo.png";
import { useMetaMask } from "metamask-react";
import Divider from "@mui/material/Divider";
import Torus from "@toruslabs/torus-embed";
import Web3 from "web3";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Web3Modal from "web3modal";


interface Props {
  handleDialogClose: any;
  getData: (addr: any, params: any) => void;

}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  title: {
    color: "#203C37 !important",
    textAlign: "center",
  },
  image: {
    height: "84px !important",
    width: "84px !important",
    alignSelf: "center",
  },
  imagebutton: {
    height: "84px !important",
    width: "84px !important",
    alignSelf: "center",
    marginTop: "23px !important",
  },
  divider: {
    backgroundColor: "#203C37 !important",
    marginLeft: "200px !important",
    marginRight: "200px !important",
  },
});

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      <Typography
        variant="h5"
        className={classes.title}
        gutterBottom
        component="div"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#203C37",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogBox({ handleDialogClose, getData }: Props) {
  const classes = useStyles();
  const { connect } = useMetaMask();

  const torusLogin = async () => {
    const torus = new Torus();

    await torus.init();
    await torus.login();

    const web3 = new Web3(torus.provider as any);
    const userInfo = await torus.getUserInfo("message");
    const address = (await web3.eth.getAccounts())[0]
    const balance = await web3.eth.getBalance(address)

    getData(userInfo, {address, balance});
    handleDialogClose();
  };

  const metamaskLogin = () => {
    connect();
    handleDialogClose();
  };

  /*
  const venlyLogin = async () => {
    // @ts-ignore
    Venly.createProviderEngine({ clientId: "Testaccount" }).then((provider) => {
      const web3 = new Web3(provider);
    });
    const options = {
      clientId: "Testaccount",
      secretType: "AVALANCHE",
      signMethod: "POPUP",
    };
    const provider = await (window as any).Venly.createProviderEngine(options);
    const web3 = new Web3(provider);
    const address = (await web3.eth.getAccounts())[0];
    const balance = await web3.eth.getBalance(address);


    const providerOptions = {
      venly: {
        package: Venly, // required
        options: {
          clientId: "testaccount", // required
        },
      },
    };

    console.log("providerOptions", providerOptions);
    const web3Modal = new Web3Modal({
      network: "testnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const instance = await web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner();
    console.log('signer', signer)
  };
*/

  return (
    <div>
      <Dialog open={true} onClose={handleDialogClose} fullWidth>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          Connect your wallet
        </BootstrapDialogTitle>
        <Divider className={classes.divider} />
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button
            className={classes.imagebutton}
            startIcon={
              <Avatar
                src={TorusLogo}
                classes={{ root: classes.image }}
                variant="square"
              />
            }
            onClick={torusLogin}
          ></Button>
          {/* <Button
            className={classes.imagebutton}
            startIcon={
              <Avatar
                src={VenlyLogo}
                classes={{ root: classes.image }}
                variant="square"
              />
            }
            onClick={venlyLogin}
          ></Button> */}
          <Button
            className={classes.imagebutton}
            startIcon={
              <Avatar
                src={MetaMask}
                classes={{ root: classes.image }}
                variant="square"
              />
            }
            onClick={metamaskLogin}
          ></Button>
        </div>
        <DialogContent>
          <DialogContentText className={classes.title}>
            <Typography variant="overline" display="block" gutterBottom>
              Click and authorize to connect!
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
